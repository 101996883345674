import React, { useState, useEffect } from 'react';
import './App.css';
import { init, postEvent } from '@telegram-apps/sdk-react';

const plans = [
  { id: 'lite', name: 'Lite', price: 100, emoji: '💡' },
  { id: 'pro', name: 'Pro', price: 300, emoji: '🚀' }
];

const durations = [
  { days: 30, multiplier: 1, emoji: '🕒' },
  { days: 60, multiplier: 2, emoji: '🕒' },
  { days: 90, multiplier: 3, emoji: '🕒' }
];

function App() {
    init();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [videoSrc, setVideoSrc] = useState('');

  // Определяем ОС пользователя
  useEffect(() => {
    const userOS = getOS();
    loadVideo(userOS);
  }, []);

  const getOS = () => {
    const userAgent = window.navigator.userAgent;
    if (/Windows/.test(userAgent)) return 'windows';
    if (/Mac/.test(userAgent)) return 'mac';
    if (/Android/.test(userAgent)) return 'android';
    if (/iPhone|iPad/.test(userAgent)) return 'ios';
    return 'default';
  };

  const loadVideo = (os) => {
    let videoFile = '';
    switch (os) {
      case 'windows':
        videoFile = 'videos/windows.mp4';
        break;
      case 'mac':
        videoFile = 'videos/mac.mp4';
        break;
      case 'android':
        videoFile = 'videos/android.mp4';
        break;
      case 'ios':
        videoFile = 'videos/ios.mp4';
        break;
      default:
        videoFile = 'videos/default.mp4';
    }
    setVideoSrc(videoFile);
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    if (selectedDuration) {
      updateTotalPrice(plan, selectedDuration);
    }
  };

  const handleDurationSelect = (duration) => {
    setSelectedDuration(duration);
    if (selectedPlan) {
      updateTotalPrice(selectedPlan, duration);
    }
  };

  const updateTotalPrice = (plan, duration) => {
    const total = plan.price * duration.multiplier;
    setTotalPrice(total);
  };

  const handlePayment = () => {
    if (!selectedPlan || !selectedDuration) {
      alert('Пожалуйста, выберите тариф и длительность.');
      return;
    }

    const tgId = new URLSearchParams(window.location.search).get('tg_id');
    const total = selectedPlan.price * selectedDuration.multiplier;

      let tagplan = 'vip-1';
      if (selectedPlan.id === 'lite' && selectedDuration.days === 30) {
          tagplan = 'vip-1';
      }
      if (selectedPlan.id === 'lite' && selectedDuration.days === 60) {
          tagplan = 'vip-1';
      }
      if (selectedPlan.id === 'lite' && selectedDuration.days === 90) {
          tagplan = 'vip-1';
      }
      if (selectedPlan.id === 'pro' && selectedDuration.days === 30) {
          tagplan = 'vip-2';
      }
      if (selectedPlan.id === 'pro' && selectedDuration.days === 60) {
          tagplan = 'vip-2';
      }
      if (selectedPlan.id === 'pro' && selectedDuration.days === 90) {
          tagplan = 'vip-2';
      }
      console.log(tagplan);

      fetch('https://greyweb.me/vpn/api/v1/getpayurl?system=bot&userId=' + tgId + '&plan=' + tagplan + '', {
          method: 'GET',
      })
          .then(response => response.json())
          .then(data => {
              let link = "";
              /*{"success":true,"message":{"status_code":200,"info":"Payment link generated"},"data":"https://auth.robokassa.ru/Merchant/Index/e466a520-242f-c7a3-cd3a-11e653db18a8"}*/
              link = data.data;
              console.log(link);
              postEvent("web_app_open_link", { url: link });
          })
          .catch(error => {
              console.error('Ошибка:', error);
          });
  };

  return (
      <div className="App">
        <div id="video-container">
          <video id="video" controls autoPlay src={videoSrc} />
        </div>

        <div className="plans-container">
          <PlanOptions plans={plans} selectedPlan={selectedPlan} onPlanSelect={handlePlanSelect} />
          <div className="separator-vertical" />
          <DurationOptions durations={durations} selectedDuration={selectedDuration} onDurationSelect={handleDurationSelect} />
          <div className="separator-vertical" />
          <Summary selectedPlan={selectedPlan} selectedDuration={selectedDuration} totalPrice={totalPrice} />
        </div>

        <button id="pay-btn" onClick={handlePayment}>
          Перейти к оплате
        </button>
      </div>
  );
}

const PlanOptions = ({ plans, selectedPlan, onPlanSelect }) => (
    <div className="plan-options">
      <h2>📋 Выберите тариф:</h2>
      <div className="card-container vertical">
        {plans.map(plan => (
            <Card
                key={plan.id}
                item={plan}
                isSelected={selectedPlan && selectedPlan.id === plan.id}
                onClick={() => onPlanSelect(plan)}
            />
        ))}
      </div>
    </div>
);

const DurationOptions = ({ durations, selectedDuration, onDurationSelect }) => (
    <div className="duration-options">
      <h2>⏳ Длительность:</h2>
      <div className="card-container vertical">
        {durations.map(duration => (
            <Card
                key={duration.days}
                item={{ name: `${duration.days} дней`, emoji: duration.emoji }}
                isSelected={selectedDuration && selectedDuration.days === duration.days}
                onClick={() => onDurationSelect(duration)}
            />
        ))}
      </div>
    </div>
);

const Summary = ({ selectedPlan, selectedDuration, totalPrice }) => (
    <div className="summary">
      <h2>💰 Итог:</h2>
      <div className="card">
        {selectedPlan && selectedDuration ? (
            <>
              <span>💰</span>
              Тариф: {selectedPlan.name}<br />
              Длительность: {selectedDuration.days} дней<br />
              Итоговая цена: {totalPrice} руб.
            </>
        ) : (
            'Выберите тариф и длительность.'
        )}
      </div>
    </div>
);

const Card = ({ item, isSelected, onClick }) => (
    <div className={`card ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <span>{item.emoji}</span>
      {item.name}
    </div>
);

export default App;
